import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Css/SectionOne.css";
import TimeIcon from "../../Assets/SectionTwoMainNewsTime.png";
import SelectedNewsOne from "../../Assets/RubrikatSelectedNewsOne.svg";
import SelectedNewsTwo from "../../Assets/RubrikatSelectedNewsTwo.svg";
import SelectedNewsThree from "../../Assets/RubrikatSelectedNewsThree.svg";

const ARTICLES_PER_PAGE = 10; // Define number of articles per page

// Function to fetch articles based on category or created in the last week
const fetchArticles = async (category, setState) => {
  try {
    let url = "";
    const now = new Date();
    const lastWeek = new Date(now.setDate(now.getDate() - 7)).toISOString();

    if (category === "temat-javore") {
      // Fetch articles created in the last week
      url = `https://vitaliteti.tframe.de/api/articles?filters[createdAt][$gte]=${lastWeek}&populate=image&sort=createdAt:desc`;
    } else {
      // Fetch articles for the selected category
      url = `https://vitaliteti.tframe.de/api/articles?filters[category][$eq]=${category}&populate=image&sort=createdAt:desc`;
    }

    const response = await fetch(url, {
      headers: {
        Authorization:
          "Bearer f658eeb57a0dbbd7771843a411581f3d7c0757a835216a362368aba5b85e4a1862802264d35d03c7bf54a40e941a2f50f9d68004cfcd88483aa7047108bcd8d208864096afc4f316b43b94dff2668e93ec3775a66835cb1660409c6302fe4ecbb3872a439bf2201282220e95bc6dfc1556a29589cf680dd446f277abf97de2b0",
      },
    });

    const data = await response.json();
    console.log(`Fetched Articles for ${category}:`, data.data);

    if (data.data && data.data.length > 0) {
      setState(data.data); // Set all articles, we'll handle pagination separately
    } else {
      console.error(`No articles found for category ${category}`);
    }
  } catch (error) {
    console.error(`Error fetching articles for category ${category}:`, error);
  }
};

// Function to convert URL-friendly category to capitalize only the first letter
const convertUrlToCategory = (urlCategory) => {
  return (
    urlCategory.charAt(0).toUpperCase() +
    urlCategory.slice(1).replace(/-/g, " ").toLowerCase()
  );
};

// Function to calculate time since the article's creation
const timeSince = (date) => {
  const now = new Date();
  const createdAt = new Date(date);
  const diffInMs = now - createdAt;

  const msInHour = 1000 * 60 * 60;
  const msInDay = msInHour * 24;
  const msInWeek = msInDay * 7;
  const msInMonth = msInDay * 30;

  if (diffInMs < msInHour) {
    return `${Math.floor(diffInMs / (1000 * 60))} minuta me pare`;
  } else if (diffInMs < msInDay) {
    return `${Math.floor(diffInMs / msInHour)} ore me pare`;
  } else if (diffInMs < msInWeek) {
    return `${Math.floor(diffInMs / msInDay)} dite me pare`;
  } else if (diffInMs < msInMonth) {
    return `${Math.floor(diffInMs / msInWeek)} jave me pare`;
  } else {
    return `${Math.floor(diffInMs / msInMonth)} muaj me pare`;
  }
};

const Rubrikat = () => {
  const { category } = useParams(); // Get category from the URL
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const navigate = useNavigate();

  const totalPages = Math.ceil(articles.length / ARTICLES_PER_PAGE); // Calculate total pages

  const handleArticleClick = (slug) => {
    navigate(`/article/${slug}`);
    window.scrollTo(0, 0);
  };

  // Handle page change
const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};


  useEffect(() => {
    const originalCategory =
      category === "temat-javore"
        ? "temat-javore"
        : convertUrlToCategory(category); // Convert hyphenated URL to original category name or check for "temat-javore"
    fetchArticles(originalCategory, setArticles);
  }, [category]);

  const extractTextFromRichText = (blocks) => {
    if (!Array.isArray(blocks)) return "";

    return blocks
      .map((block) => {
        if (block.children && Array.isArray(block.children)) {
          return block.children.map((child) => child.text).join(" ");
        }
        return "";
      })
      .join(" ");
  };

  if (articles.length === 0) {
    return <div className="rubrikat">Loading...</div>; // Show a loading message while fetching
  }

  // Paginate articles
  const startIndex = (currentPage - 1) * ARTICLES_PER_PAGE;
  const paginatedArticles = articles.slice(startIndex, startIndex + ARTICLES_PER_PAGE);

  const mainArticle = paginatedArticles[0];
  const otherArticles = paginatedArticles.slice(1);

  return (
    <div className="rubrikat">
      <div className="rubrikat-header">
        <h1 className="rubrikat-header-title">{category.replace(/-/g, " ")}</h1>
        <div className="rubrikat-main">
          {mainArticle && (
            <>
              <img
                src={`https://vitaliteti.tframe.de${mainArticle.attributes.image?.data?.attributes?.url}`}
                className="rubrikat-main-image"
                alt={mainArticle.attributes.title}
                onClick={() => handleArticleClick(mainArticle.attributes.slug)}
                style={{ cursor: "pointer" }}
              />
              <div className="rubrikat-main-information">
                <h1
                  className="rubrikat-main-information-title"
                  onClick={() =>
                    handleArticleClick(mainArticle.attributes.slug)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {mainArticle.attributes.title}
                </h1>
                <h1 className="rubrikat-main-information-text">
                  {extractTextFromRichText(mainArticle.attributes.description)}
                </h1>
                <h1 className="rubrikat-main-information-author">
                  {mainArticle.attributes.author
                    ? "Nga " + mainArticle.attributes.author
                    : "Autori i panjohur"}
                </h1>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="rubrikat-other">
        <h1 className="rubrikat-other-title">Te fundit</h1>
      </div>
      <div className="rubrikat-main-2">
        <div className="rubrika-seperation">
          {otherArticles.map((article, index) => (
            <div key={index} className="rubrikat-main-news-card">
              <img
                src={`https://vitaliteti.tframe.de${article.attributes.image?.data?.attributes?.url}`}
                className="rubrikat-main-news-image"
                alt={article.attributes.title}
                onClick={() => handleArticleClick(article.attributes.slug)}
                style={{ cursor: "pointer" }}
              />
              <div className="rubrikat-main-news-card-information">
                <div>
                  <h1
                    className="rubrikat-main-news-title"
                    onClick={() =>
                      handleArticleClick(article.attributes.slug)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {article.attributes.title}
                  </h1>
                  <h1 className="rubrikat-main-news-text">
                    {extractTextFromRichText(article.attributes.description)}
                  </h1>
                </div>
                <div className="rubrikat-main-news-time">
                  <img src={TimeIcon} alt="Time Icon" />
                  <h1 className="rubrikat-main-news-time-text">
                    {timeSince(article.attributes.createdAt)}
                  </h1>
                </div>
              </div>
            </div>
          ))}
        </div>
       
        <div className="rubrikat-selected">
          <div className="rubrikat-selected-title">
            <h1 className="rubrikat-selected-title-text">
              Trend Ushqimi dhe Dieta
            </h1>
          </div>
          <div className="rubrikat-selected-news-card">
            <img src={SelectedNewsOne} />
            <h1 className="rubrikat-selected-news-card-title">
              Trajtimi i akneve me produktet e natyres
            </h1>
          </div>
          <div className="rubrikat-selected-news-card">
            <img src={SelectedNewsTwo} />
            <h1 className="rubrikat-selected-news-card-title">
              Trajtimi i akneve me produktet e natyres
            </h1>
          </div>
          <div className="rubrikat-selected-news-card">
            <img src={SelectedNewsThree} />
            <h1 className="rubrikat-selected-news-card-title">
              Trajtimi i akneve me produktet e natyres
            </h1>
          </div>
        </div>
      </div>
      <div className="pagination">
  {Array.from({ length: totalPages }, (_, index) => (
    <button
      key={index}
      onClick={() => handlePageChange(index + 1)}
      className={`pagination-button ${
        currentPage === index + 1 ? "active-page" : ""
      }`}
    >
      {index + 1}
    </button>
  ))}
</div>

    </div>
  );
};

export default Rubrikat;
