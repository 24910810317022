import React from "react";
import "../Css/Header.css";
// import Logo from "../../Assets/HeaderLogo.svg";
import Logo from "../../Assets/Vitaliteti-logo.svg";
import Search from "../../Assets/HeaderSearch.png";
import { Link, useNavigate } from "react-router-dom";
import { ThemeToggleButton } from "./ThemeToggle";

const Header = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearchClick = () => {
    navigate("/search");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header">
      <div
        className="header-logo"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          style={{
            width: "auto",
            height: "85%",
          }}
          src={Logo}
          alt="Logo"
          onClick={handleLogoClick}
        />
      </div>
      <div className="header-list">
        <Link
          to="/rubrikat/keshilla-mjekesore"
          onClick={() => window.scrollTo(0, 0)}
          className="header-list-text"
        >
          Keshilla mjekesore
        </Link>
        <Link
          to="/rubrikat/ushqimi-dhe-dieta"
          onClick={() => window.scrollTo(0, 0)}
          className="header-list-text"
        >
          Ushqimi dhe dieta
        </Link>
        <Link
          to="/rubrikat/psikologji"
          onClick={() => window.scrollTo(0, 0)}
          className="header-list-text"
        >
          Psikologji
        </Link>
        <Link
          to="/rubrikat/shendetesi-lajme"
          onClick={() => window.scrollTo(0, 0)}
          className="header-list-text"
        >
          Shendetesi & lajme
        </Link>
        <Link
          to="/rubrikat/temat-javore"
          onClick={() => window.scrollTo(0, 0)}
          className="header-list-text"
        >
          Temat javore
        </Link>
        <Link
          onClick={() => window.scrollTo(0, 0)}
          className="header-list-text"
        >
          Te tjerat
        </Link>
        <ThemeToggleButton lassName="header-list-text" />
        <button className="header-search-button" onClick={handleSearchClick}>
          <img src={Search} className="header-search-img" alt="Search" />
        </button>
      </div>
    </div>
  );
};

export default Header;
