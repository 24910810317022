import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Css/SectionThree.css";
import Arrow from "../../Assets/SectionTwoArrow.png";
import Reklama from "../../Assets/SectionThreeReklama.svg";
import MainNewsTime from "../../Assets/SectionTwoMainNewsTime.png";
import NewsOne from "../../Assets/SectionThreeNewsOne.svg";
import NewsTwo from "../../Assets/SectionThreeNewsTwo.svg";
import NewsThree from "../../Assets/SectionThreeNewsThree.svg";
import NewsFour from "../../Assets/SectionThreeNewsFour.svg";
import NewsFive from "../../Assets/SectionThreeNewsFive.svg";
import NewsSix from "../../Assets/SectionThreeNewsSix.svg";

const SectionThree = () => {
  const navigate = useNavigate();

  const handleButtonClick = (query) => {
    navigate("/search", { state: { query } });
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });  };

  const newsItems = [
    {
      title: "Gjërat që duhet të bëni nëse kaloni shumë kohë në këmbë gjatë ditës",
      image: NewsOne,
      time: "3 javë më parë",
    },
    {
      title: "Kur është koha më e mirë për të larë dhëmbët?",
      image: NewsTwo,
      time: "3 javë më parë",
    },
    {
      title: "Si të Arrini Moshën 85 e Më Shumë",
      image: NewsThree,
      time: "3 javë më parë",
    },
    {
      title: "Si të Përmirësoni Jetën Tuaj Sociale pa Dalë nga Shtëpia",
      image: NewsFour,
      time: "3 javë më parë",
    },
    {
      title: "3 Ushqime Kryesore për Lëkurën e Shëndetshme: Vlerat e Vitamineve dhe Antioksidantëve",
      image: NewsFive,
      time: "3 javë më parë",
    },
    {
      title: "5 Ide të Krijuara për një Mbrëmje të Përsosur në Shtëpi",
      image: NewsSix,
      time: "3 javë më parë",
    },
  ];

  return (
    <div className="section-three">
      <div className="section-three-header">
        <h1 className="section-three-header-title">Te tjera</h1>
        <div className="section-three-header-link">
          <Link className="section-three-header-link-text">
            SHIKO TË GJITHA “TE TJERA”
          </Link>
          <img src={Arrow} alt="Arrow" />
        </div>
      </div>
      <div className="section-three-body">
        <div className="section-three-news">
          {newsItems.map((item, index) => (
            <div
              className={`section-three-news-card ${
                index === newsItems.length - 1 ? "section-three-news-card-last" : ""
              }`}
              key={index}
            >
              <div className="section-three-news-card-info">
                <button
                  className="section-three-news-card-button"
                  onClick={() => handleButtonClick("TE TJERA")}
                >
                  TE TJERA
                </button>
                <h1 className="section-three-news-card-title">{item.title}</h1>
                <div className="section-three-news-card-time">
                  <img src={MainNewsTime} alt="Time Icon" />
                  <h1 className="section-three-news-card-time-text">{item.time}</h1>
                </div>
              </div>
              <img src={item.image} className="section-three-news-card-image" alt="News" />
            </div>
          ))}
        </div>
        <div>
          <img src={Reklama} className="section-three-reklama" alt="Reklama" />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
