import React from "react";
import Header from "./Components/Header";
import SectionOne from "./Components/SectionOne";
import HeaderMobile from "./Components/HeaderMobile";
import "./Homepage.css";
import SectionTwo from "./Components/SectionTwo";
import SectionThree from "./Components/SectionThree";
import Footer from "./Components/Footer";

const Homepage = () => {
  return (
    <div className="homepage-container">
      <div className="HomepageMobileHeader">
        <HeaderMobile />
      </div>
      <div className="HomepageHeader">
        <Header />
      </div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Footer />
    </div>
  );
};

export default Homepage;
