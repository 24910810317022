import React from "react";
import Header from "../Homepage/Components/Header";
import HeaderMobile from "../Homepage/Components/HeaderMobile";
import Footer from "../Homepage/Components/Footer";
import Section from "./Components/Section";

const Article = () => {
  return (
    <div className="homepage-container">
      <div className="HomepageMobileHeader">
        <HeaderMobile />
      </div>
      <div className="HomepageHeader">
        <Header />{" "}
      </div>
      <Section />
      <Footer />
    </div>
  );
};

export default Article;
