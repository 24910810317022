import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Homepage/Homepage";
import Search from "./Search/Search";
import Article from "./Article/Article";
import Rubrikat from "./Rubrikat/Rubrikat";
import ThemeContextProvider from "./themeContext";

const App = () => {
  return (
    <ThemeContextProvider>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/search" element={<Search />} />
        <Route path="/article/:slug" element={<Article />} />
        <Route path="/rubrikat/:category" element={<Rubrikat />} />
      </Routes>
    </ThemeContextProvider>
  );
};

export default App;
