import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Css/SectionTwo.scss";
import Arrow from "../../Assets/SectionTwoArrow.png";
import MainNewsTime from "../../Assets/SectionTwoMainNewsTime.png";

const SectionTwo = () => {
  const navigate = useNavigate();
  const [mainNews, setMainNews] = useState([]);
  const [keshillaMjekesore, setKeshillaMjekesore] = useState([]);
  const [psikologjia, setPsikologjia] = useState([]);
  const [ushqimiDieta, setUshqimiDieta] = useState([]);
  const [shendetesiLajme, setShendetesiLajme] = useState([]);
  const textRefs = useRef([]); // to keep references for all text elements

  useEffect(() => {
    const fetchArticles = async (category, setState) => {
      try {
        const response = await fetch(
          `https://vitaliteti.tframe.de/api/articles?filters[category][$eq]=${category}&populate=image&sort=createdAt:desc`,
          {
            headers: {
              Authorization:
                "Bearer f658eeb57a0dbbd7771843a411581f3d7c0757a835216a362368aba5b85e4a1862802264d35d03c7bf54a40e941a2f50f9d68004cfcd88483aa7047108bcd8d208864096afc4f316b43b94dff2668e93ec3775a66835cb1660409c6302fe4ecbb3872a439bf2201282220e95bc6dfc1556a29589cf680dd446f277abf97de2b0",
            },
          }
        );

        const data = await response.json();
        if (data.data && data.data.length > 0) {
          setState(data.data.slice(0, 4)); // Limit to 4 most recent articles
        } else {
          console.error(`No articles found for category ${category}`);
        }
      } catch (error) {
        console.error(
          `Error fetching articles for category ${category}:`,
          error
        );
      }
    };

    const fetchMainNews = async () => {
      try {
        const response = await fetch(
          "https://vitaliteti.tframe.de/api/articles?populate=image",
          {
            headers: {
              Authorization:
                "Bearer f658eeb57a0dbbd7771843a411581f3d7c0757a835216a362368aba5b85e4a1862802264d35d03c7bf54a40e941a2f50f9d68004cfcd88483aa7047108bcd8d208864096afc4f316b43b94dff2668e93ec3775a66835cb1660409c6302fe4ecbb3872a439bf2201282220e95bc6dfc1556a29589cf680dd446f277abf97de2b0",
            },
          }
        );

        const data = await response.json();

        if (data.data && data.data.length > 0) {
          const filteredArticles = data.data
            .filter((article) => article.attributes.subcategory === "1")
            .slice(0, 3); // Limit to 3 articles

          setMainNews(filteredArticles);
        } else {
          console.error("No articles found with the specified criteria");
        }
      } catch (error) {
        console.error("Error fetching the articles:", error);
      }
    };

    fetchMainNews();
    fetchArticles("Keshilla mjekesore", setKeshillaMjekesore);
    fetchArticles("Psikologji", setPsikologjia);
    fetchArticles("Ushqimi dhe dieta", setUshqimiDieta);
    fetchArticles("Shendetesi lajme", setShendetesiLajme);
  }, []);

  const handleArticleClick = (slug) => {
    navigate(`/article/${slug}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  const handleButtonClick = (query) => {
    navigate("/search", { state: { query } });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavigateToRubrikat = (category) => {
    navigate(`/rubrikat/${category.replace(/\s+/g, "-").toLowerCase()}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  const timeSince = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInMs = now - createdAt;

    const msInHour = 1000 * 60 * 60;
    const msInDay = msInHour * 24;
    const msInWeek = msInDay * 7;
    const msInMonth = msInDay * 30;

    if (diffInMs < msInHour) {
      return `${Math.floor(diffInMs / (1000 * 60))} minuta me pare`;
    } else if (diffInMs < msInDay) {
      return `${Math.floor(diffInMs / msInHour)} ore me pare`;
    } else if (diffInMs < msInWeek) {
      return `${Math.floor(diffInMs / msInDay)} dite me pare`;
    } else if (diffInMs < msInMonth) {
      return `${Math.floor(diffInMs / msInWeek)} jave me pare`;
    } else {
      return `${Math.floor(diffInMs / msInMonth)} muaj me pare`;
    }
  };

  const extractTextFromDescription = (description) => {
    if (Array.isArray(description)) {
      return description
        .map((block) => block.children.map((child) => child.text).join(" "))
        .join(" ");
    }
    return "";
  };

  const truncateText = (element) => {
    const maxHeight = element.clientHeight;
    let fullText = element.textContent;
    let truncatedText = fullText;

    while (element.scrollHeight > maxHeight) {
      truncatedText = truncatedText.slice(0, -1);
      element.textContent = truncatedText + "...";
    }
  };

  useEffect(() => {
    textRefs.current.forEach((element) => {
      if (element) {
        truncateText(element);
      }
    });
  }, [mainNews, keshillaMjekesore, psikologjia, ushqimiDieta, shendetesiLajme]);

  const renderArticles = (articles) => {
    return articles.map((article, index) => {
      const description = extractTextFromDescription(
        article.attributes.description
      );
      const imageUrl = article.attributes.image?.data?.attributes?.url;

      return (
        <div className="section-two-news-card" key={article.id}>
          <div className="section-two-news-card-hover">
            {imageUrl ? (
              <img
                src={`https://vitaliteti.tframe.de${imageUrl}`}
                className="section-two-news-card-image"
                alt={article.attributes.title}
                style={{ cursor: "pointer" }}
                onClick={() => handleArticleClick(article.attributes.slug)}
              />
            ) : (
              <div
                className="section-two-news-card-image-placeholder"
                style={{ cursor: "pointer" }}
                onClick={() => handleArticleClick(article.attributes.slug)}
              >
                No Image Available
              </div>
            )}

            <button
              className="section-two-news-card-button"
              onClick={() =>
                handleNavigateToRubrikat(article.attributes.category)
              }
            >
              {article.attributes.category}
            </button>
            <h1
              className="section-two-news-card-title"
              onClick={() => handleArticleClick(article.attributes.slug)}
              style={{ cursor: "pointer" }}
            >
              {article.attributes.title}
            </h1>
          </div>

          <h1
            className="section-two-news-card-text"
            ref={(el) => (textRefs.current[index] = el)} // Track ref to dynamically truncate
          >
            {description}
          </h1>

          <div className="section-two-news-card-time">
            <img src={MainNewsTime} alt="Time Icon" />
            <h1 className="section-two-news-card-time-text">
              {timeSince(article.attributes.createdAt)}
            </h1>
          </div>
        </div>
      );
    });
  };

  const categories = [
    "Alzhaimer",
    "Kanceri Gjirit",
    "Crregullime te tretjes",
    "Depresioni",
    "Semundjet e zemres",
    "Semundjet e veshkave",
    "Kanceri mushkerive",
    "Koliti Ulceroz",
    "Migrena",
    "Psoriasa",
    "Artrit rheumatoid",
    "Kushtet e lekures",
    "Diabeti i tipit 1",
    "Diabeti i tipit 2",
  ];

  return (
    <div className="section-two">
      <div className="section-two-header">
        <h1 className="section-two-header-title">Artikujt kryesor</h1>
        <div className="section-two-header-link">
          <Link className="section-two-header-link-text">
            SHIKO TË GJITHA “LAJMET KRYESORE”
          </Link>
          <img src={Arrow} />
        </div>
      </div>
      <div className="section-two-categories">
        {categories.map((category) => (
          <button
            key={category}
            className="section-two-categories-button"
            onClick={() => handleButtonClick(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="section-two-header-link-mobile">
        <Link className="section-two-header-link-text">
          SHIKO TË GJITHA “LAJMET KRYESORE”
        </Link>
        <img src={Arrow} />
      </div>
      <div className="section-two-main-news">
        {mainNews.map((newsItem) => {
          const description = extractTextFromDescription(
            newsItem.attributes.description
          );
          const firstTwoSentences =
            description.split(".").slice(0, 2).join(".") + ".";
          const imageUrl =
            "https://vitaliteti.tframe.de" +
            newsItem.attributes.image.data.attributes.formats.medium.url;

          return (
            <div className="section-two-main-news-card" key={newsItem.id}>
              <img
                src={imageUrl}
                className="section-two-main-news-card-image"
                alt={newsItem.attributes.title}
                style={{ cursor: "pointer" }}
                onClick={() => handleArticleClick(newsItem.attributes.slug)}
              />
              <button
                className="section-two-main-news-card-button"
                onClick={() =>
                  handleNavigateToRubrikat(newsItem.attributes.category)
                }
              >
                {newsItem.attributes.category}
              </button>
              <h1
                className="section-two-main-news-card-title"
                onClick={() => handleArticleClick(newsItem.attributes.slug)}
                style={{ cursor: "pointer" }}
              >
                {newsItem.attributes.title}
              </h1>
              <h1 className="section-two-main-news-card-text">
                {firstTwoSentences}
              </h1>
              <div className="section-two-main-news-card-time">
                <img src={MainNewsTime} alt="Time Icon" />
                <h1 className="section-two-main-news-card-time-text">
                  {timeSince(newsItem.attributes.createdAt)}
                </h1>
              </div>
            </div>
          );
        })}
      </div>

      <div className="section-two-header">
        <h1 className="section-two-header-title">Keshilla mjekesore</h1>
        <div className="section-two-header-link">
          <Link
            to="/rubrikat/keshilla-mjekesore"
            className="section-two-header-link-text"
          >
            SHIKO TË GJITHA “KESHILLAT MJEKESORE”
          </Link>
          <img src={Arrow} />
        </div>
      </div>
      <div className="section-two-news">
        {renderArticles(keshillaMjekesore)}
      </div>

      <div className="section-two-header">
        <h1 className="section-two-header-title">Psikologjia</h1>
        <div className="section-two-header-link">
          <Link
            to="/rubrikat/psikologji"
            className="section-two-header-link-text"
          >
            SHIKO TË GJITHA “PSIKOLOGJIA”
          </Link>
          <img src={Arrow} />
        </div>
      </div>
      <div className="section-two-news">{renderArticles(psikologjia)}</div>

      <div className="section-two-header">
        <h1 className="section-two-header-title">Ushqimi dhe Dieta</h1>
        <div className="section-two-header-link">
          <Link
            to="/rubrikat/ushqimi-dieta"
            className="section-two-header-link-text"
          >
            SHIKO TË GJITHA “USHQIMI DHE DIETA”
          </Link>
          <img src={Arrow} />
        </div>
      </div>
      <div className="section-two-news">{renderArticles(ushqimiDieta)}</div>

      <div className="section-two-header">
        <h1 className="section-two-header-title">Shendetesi Lajme</h1>
        <div className="section-two-header-link">
          <Link
            to="/rubrikat/shendetesi-lajme"
            className="section-two-header-link-text"
          >
            SHIKO TË GJITHA “SHENDETESI LAJME”
          </Link>
          <img src={Arrow} />
        </div>
      </div>
      <div className="section-two-news">{renderArticles(shendetesiLajme)}</div>
    </div>
  );
};

export default SectionTwo;
