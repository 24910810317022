import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Css/HeaderMobile.css";
// import Logo from "../../Assets/HeaderMobileLogo.svg";
import Logo from "../../Assets/Vitaliteti-logo.svg";
import Nav from "../../Assets/HeaderMobileNav.svg";
import SearchIcon from "../../Assets/SearchIcon.png";
import { ThemeToggleButton } from "./ThemeToggle";

const HeaderMobile = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchClick = () => {
    navigate("/search", { state: { query: searchText } });
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  return (
    <div className="header-mobile">
      <div className="header-content">
        <img
          src={Logo}
          className="header-mobile-logo"
          alt="Logo"
          onClick={handleLogoClick}
        />
        <button className="header-mobile-nav" onClick={toggleDropdown}>
          <img src={Nav} alt="Navigation" />
        </button>
      </div>
      {isDropdownOpen && (
        <div className="header-mobile-dropdown" ref={dropdownRef}>
          <Link
            className="mobile-header-list-text"
            onClick={() => window.scrollTo(0, 0)}
            to="/rubrikat/keshilla-mjekesore"
          >
            Keshilla mjekesore
          </Link>
          <Link
            className="mobile-header-list-text"
            onClick={() => window.scrollTo(0, 0)}
            to="/rubrikat/ushqimi-dhe-dieta"
          >
            Ushqimi dhe dieta
          </Link>
          <Link
            className="mobile-header-list-text"
            onClick={() => window.scrollTo(0, 0)}
            to="/rubrikat/psikologji"
          >
            Psikologji
          </Link>
          <Link
            className="mobile-header-list-text"
            to="/rubrikat/shendetesi-lajme"
          >
            Shendetesi & lajme
          </Link>
          <Link
            className="mobile-header-list-text"
            onClick={() => window.scrollTo(0, 0)}
            to="/rubrikat/temat-javore"
          >
            Temat javore
          </Link>
          <Link
            className="mobile-header-list-text"
            onClick={() => window.scrollTo(0, 0)}
            to="/rubrikat/te-tjerat"
          >
            Te tjerat
          </Link>
          <div className="mobile-header-list-text">
            <ThemeToggleButton />
          </div>
          <div className="header-mobile-search-container">
            <img
              src={SearchIcon}
              className="header-mobile-search-icon"
              alt="Search"
              onClick={handleSearchClick}
            />
            <input
              type="text"
              className="header-mobile-search"
              placeholder="Kërko lajme"
              value={searchText}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMobile;
