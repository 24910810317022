import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/SectionOne.css";
import Banner from "../../Assets/SectionOneBanner.svg";
import Certified from "../../Assets/SectionOneCertified.jpg";
import Heart from "../../Assets/SectionOneHeart.png";
import Doctor from "../../Assets/SectionOneDoctor.png";
import GreenDot from "../../Assets/SectionOneCenterGreenDot.svg";

const SectionOne = () => {
  const navigate = useNavigate();
  const [oldestArticle, setOldestArticle] = useState(null);
  const [recentArticles, setRecentArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          "https://vitaliteti.tframe.de/api/articles?populate=image",
          {
            headers: {
              Authorization:
                "Bearer f658eeb57a0dbbd7771843a411581f3d7c0757a835216a362368aba5b85e4a1862802264d35d03c7bf54a40e941a2f50f9d68004cfcd88483aa7047108bcd8d208864096afc4f316b43b94dff2668e93ec3775a66835cb1660409c6302fe4ecbb3872a439bf2201282220e95bc6dfc1556a29589cf680dd446f277abf97de2b0",
            },
          }
        );

        const data = await response.json();
        console.log("API Response:", data);

        if (data.data && data.data.length > 0) {
          // Sort articles by creation date
          const sortedArticles = data.data.sort(
            (a, b) =>
              new Date(a.attributes.createdAt) -
              new Date(b.attributes.createdAt)
          );

          // Get the oldest article (first one after sorting)
          const firstArticle = sortedArticles[0].attributes;
          const baseUrl = "https://vitaliteti.tframe.de";

          // Extract the first sentence from the description
          const firstSentence =
            firstArticle.description[0].children[0].text.split(".")[0] + ".";

          setOldestArticle({
            image:
              baseUrl + firstArticle.image.data.attributes.formats.medium.url,
            title: firstArticle.title,
            category: firstArticle.category,
            description: firstSentence,
            fullDescription: firstArticle.description,
            author: firstArticle.author
              ? "Nga" + firstArticle.author
              : "Autori i Panjohur", // Default to "Autori i Panjohur" if author is null
            slug: firstArticle.slug,
          });

          // Get the 5 most recent articles
          const mostRecentArticles = sortedArticles
            .reverse() // Reverse sorted array to get most recent articles
            .slice(0, 5);

          setRecentArticles(mostRecentArticles);
        } else {
          console.error("No article found");
        }
      } catch (error) {
        console.error("Error fetching the article:", error);
      }
    };

    fetchArticles();
  }, []);

  const handleArticleClick = (slug) => {
    navigate(`/article/${slug}`);
    window.scrollTo(0, 0);
  };

  const handleButtonClick = (query) => {
    navigate("/search", { state: { query } });
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  const timeSince = (date) => {
    const now = new Date();
    const createdAt = new Date(date);
    const diffInMs = now - createdAt;

    const msInHour = 1000 * 60 * 60;
    const msInDay = msInHour * 24;
    const msInWeek = msInDay * 7;
    const msInMonth = msInDay * 30;

    if (diffInMs < msInHour) {
      return `${Math.floor(diffInMs / (1000 * 60))} minuta me pare`;
    } else if (diffInMs < msInDay) {
      return `${Math.floor(diffInMs / msInHour)} ore me pare`;
    } else if (diffInMs < msInWeek) {
      return `${Math.floor(diffInMs / msInDay)} dite me pare`;
    } else if (diffInMs < msInMonth) {
      return `${Math.floor(diffInMs / msInWeek)} jave me pare`;
    } else {
      return `${Math.floor(diffInMs / msInMonth)} muaj me pare`;
    }
  };

  if (!oldestArticle) {
    return <div className="section-one">Loading...</div>;
  }

  return (
    <div className="section-one">
      <div className="section-one-part-1">
        <div className="section-one-part-1-group">
          <div className="section-one-part-1-bolded">
            <img src={Certified} className="section-one-part-1-image" />
            <h1 className="section-one-part-1-stat-text">10+</h1>
          </div>
          <h1 className="section-one-part-1-text">
            {" "}
            Vite eksperience ne keshilla
          </h1>
        </div>
        <h1 className="section-one-part-1-divide-text">|</h1>
        <div className="section-one-part-1-group">
          <div className="section-one-part-1-bolded">
            <img src={Heart} className="section-one-part-1-image" />
            <h1 className="section-one-part-1-stat-text">250k</h1>
          </div>
          <h1 className="section-one-part-1-text"> Shfletime te artikujve</h1>
        </div>
        <h1 className="section-one-part-1-divide-text">|</h1>
        <div className="section-one-part-1-group">
          <div className="section-one-part-1-bolded">
            <img src={Doctor} className="section-one-part-1-image" />
            <h1 className="section-one-part-1-stat-text">50+</h1>
          </div>
          <h1 className="section-one-part-1-text">Ekspert medicinal</h1>
        </div>
      </div>

      <div className="section-one-part-2">
        <div className="section-one-part-2-left">
          <img
            src={oldestArticle.image}
            className="section-one-part-2-left-image"
            alt="News Header"
            onClick={() => handleArticleClick(oldestArticle.slug)}
            style={{ cursor: "pointer" }}
          />
          <button
            className="section-one-part-2-left-button"
            onClick={() => handleButtonClick(`${oldestArticle.category}`)}
          >
            {oldestArticle.category}
          </button>
          <h1
            className="section-one-part-2-left-title"
            onClick={() => handleArticleClick(oldestArticle.slug)}
            style={{ cursor: "pointer" }}
          >
            {oldestArticle.title}
          </h1>
          <h1 className="section-one-part-2-left-text">
            {oldestArticle.description}
          </h1>
          <h1 className="section-one-part-2-left-author">
            {oldestArticle.author}
          </h1>
        </div>

        <div className="section-one-part-2-center">
          <div className="section-one-part-2-center-header">
            <h1 className="section-one-part-2-center-header-text">
              Lajmet e fundit
            </h1>
          </div>
          {recentArticles.map((articleItem) => (
            <div
              className="section-one-part-2-center-news"
              key={articleItem.id}
            >
              <h1
                className="section-one-part-2-center-news-title"
                onClick={() => handleArticleClick(articleItem.attributes.slug)}
              >
                {articleItem.attributes.title}
              </h1>
              <div className="section-one-part-2-center-news-information">
                <p className="section-one-part-2-center-news-information-time">
                  {timeSince(articleItem.attributes.createdAt)}
                </p>
                <img src={GreenDot} alt="Separator" />
                <h1 className="section-one-part-2-center-news-information-author">
                  Nga {articleItem.attributes.author || "Autori i Panjohur"}
                </h1>
              </div>
            </div>
          ))}
        </div>

        <div className="section-one-part-2-right">
          <img src={Banner} alt="Banner" />
          <div className="section-one-part-2-right-categories">
            <h1 className="section-one-part-2-right-title">
              Temat me te kerkuara
            </h1>
            <div className="section-one-part-2-right-buttons">
              <button
                className="section-one-part-2-right-button"
                onClick={() => handleButtonClick("Best Time for Coffee")}
              >
                Best Time for Coffee
              </button>
              <button
                className="section-one-part-2-right-button"
                onClick={() => handleButtonClick("High Protein Gains")}
              >
                High Protein Gains
              </button>
              <button
                className="section-one-part-2-right-button"
                onClick={() => handleButtonClick("Electrolytes")}
              >
                Electrolytes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
