import React from "react";
import Header from "../Homepage/Components/Header";
import SectionOne from "./Components/SectionOne";
import Footer from "../Homepage/Components/Footer";
import HeaderMobile from "../Homepage/Components/HeaderMobile";

const Search = () => {
  return (
    <div className="homepage-container">
      <div className="HomepageMobileHeader">
        <HeaderMobile />
      </div>
      <div className="HomepageHeader">
        <Header />
      </div>{" "}
      <SectionOne />
      <Footer />
    </div>
  );
};

export default Search;
