import React from "react";
import HeaderMobile from "../Homepage/Components/HeaderMobile";
import Header from "../Homepage/Components/Header";
import SectionOne from "./Components/SectionOne";
import "./Rubrikat.css";
import Footer from "../Homepage/Components/Footer";

const Rubrikat = () => {
  return (
    <div className="homepage-container">
      <div className="HomepageMobileHeader">
        <HeaderMobile />
      </div>
      <div className="HomepageHeader">
        <Header />
      </div>
      <SectionOne />
      <Footer />
    </div>
  );
};

export default Rubrikat;
